import { useState } from 'react';
import 'antd/dist/antd.min.css';
import { Layout, Alert } from 'antd';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CustomSider from './components/Sider/Sider';
import GlobalStyles from './components/GlobalStyles';
import { useSecurityContext, useDeepEffect, useCubejsApi } from './hooks';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MetaContext } from './components/MetaContext';
import { FilterValuesContext } from './components/FilterValuesContext';
import { playgroundContext, buildApiUrl, backendApiUrl } from './config';
import { CubeProvider } from '@cubejs-client/react';

const StyledLayoutContent = styled(Layout.Content)`
  height: 100%;
`;

function App(props) {
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const [fatalError, setFatalError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { children } = props;
  let { 
    isLoggedIn, 
    decodeCheckExpiredToken, 
    token,
  } = useSecurityContext();
  const cubejsApi = useCubejsApi(apiUrl, token);

  useDeepEffect(() => {
    window.addEventListener('unhandledrejection', (promiseRejectionEvent) => {
      const error = promiseRejectionEvent.reason;
      console.log(error);
      const e = (error.stack || error).toString();
    });
    if (isLoggedIn){
      console.log('checking token expiration...')
      decodeCheckExpiredToken();
    }
    if (fatalError) {
      console.log(fatalError.stack);
    }
  }, [token, location])

  return (
    <CubeProvider cubejsApi={cubejsApi}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Layout>
        <GlobalStyles />
        <Header collapsed={collapsed}/>
        <Layout>
        {isLoggedIn && <CustomSider collapsed={collapsed} setCollapsed={setCollapsed}/>}
          <MetaContext apiUrl={backendApiUrl} cubejsToken={token}>
            <FilterValuesContext apiUrl={apiUrl} cubejsToken={token}>
              <StyledLayoutContent>
                {fatalError ? (
                  <Alert
                    message="Error occured while rendering"
                    description={fatalError.stack || ''}
                    type="error"
                  />
                ) : (
                  children
                )}
                <Footer />
              </StyledLayoutContent>
            </FilterValuesContext>
          </MetaContext>
          </Layout>
        
      </Layout>
    </GoogleOAuthProvider>
    </CubeProvider>
  );
  
}

export default App;