import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: 24px;
  margin-bottom: 16px;
  > *:first-child {
    margin-bottom: 8px;
  }
`;