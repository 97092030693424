import {
  ReactFlow,
  Background,
  Position,
  MarkerType,
  useNodesState,
  useEdgesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './xy-theme.css';
import BiDirectionalEdge from './BiDirectionalEdge';
import DisinterestNode from './DisinterestNode';
import RetentionNode from './RetentionNode';
import SubscriptionNode from './SubscriptionNode';
import RegistrationNode from './RegistrationNode';
import ChurnNode from './ChurnNode';
import FirstVisitNode from './FirstVisitNode';
import LabelNode from './LabelNode';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
 
const initialNodes = [
  {
    id: 'engagement',
    type: 'group',
    data: {label: 'Engagement'},
    position: { x: 0, y: -56 },
    style: {
      width: '100%',
      maxWidth: 1300,
      height: 200,
      zIndex: -1,
      background: '#feffe6',
      borderColor: '#ffffb8'
    },
    draggable: false,
    selectable: false
  },
  {
    id: 'states',
    type: 'group',
    data: {label: 'Stage'},
    
    position: { x: 0, y: 156 },
    style: {
      width: '100%',
      maxWidth: 1300,
      height: 200,
      zIndex: -1,
      background: '#fff7e6',
      borderColor: '#ffe7ba'
    },
    draggable: false,
    selectable: false
  },
  {
    id: 'label_engagement',
    type: 'label',
    position: { x: 1, y: 1 },
    data: {label: 'Engagement'},
    parentId: 'engagement',
    extent: 'parent',
    style: {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: '#ffffb8',
      borderRadius: '8px 0px'
    },
    draggable: false,
    selectable: false
  },
  {
    id: 'label_states',
    type: 'label',
    position: { x: 1, y: 1 },
    data: {label: 'Stage'},
    parentId: 'states',
    extent: 'parent',
    style: {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: '#ffe7ba',
      borderRadius: '8px 0px'
    },
    draggable: false,
    selectable: false
  },
  {
    id: '1_first-visit',
    data: { label: 'First Visit' },
    position: { x: 20, y: 80 },
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
    type: 'firstvisit',
    parentId: 'states',
    extent: 'parent',
  },
  {
    id: '2_registration',
    data: { label: 'Registration' },
    position: { x: 290, y: 80 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    type: 'registration',
    parentId: 'states',
    extent: 'parent',
  },
  {
    id: '3_subscription',
    data: { label: 'Subscription' },
    type: 'subscription',
    position: { x: 540, y: 80 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    parentId: 'states',
    extent: 'parent',
  },
  {
    id: '4_retention',
    data: { label: 'Retention' },
    position: { x: 540, y: 80 },
    type: 'retention',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    parentId: 'engagement',
    extent: 'parent',
  },
  {
    id: '5_disinterest',
    data: { label: 'Disinterest' },
    position: { x: 810, y: 80 },
    type: 'disinterest',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    parentId: 'engagement',
    extent: 'parent',
  },
  {
    id: '6_churn',
    data: { label: 'Churn' },
    position: { x: 980, y: 80 },
    targetPosition: Position.Left,
    type: 'churn',
    parentId: 'states',
    extent: 'parent',
  },
];
 
const initialEdges = [
  { 
    id: '1-2', 
    source: '1_first-visit', 
    target: '2_registration', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: "0.44%",
  },
  { 
    id: '2-3', 
    source: '2_registration', 
    target: '3_subscription', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    targetHandle: 'left',
    sourceHandle: 'source',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: "3.11%",
  },
  { 
    id: '2-4', 
    source: '2_registration', 
    target: '4_retention', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    type: 'step',
    targetHandle: 'left-target',
    animated: true,
    sourceHandle: 'top-source-2',
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: '10.63%'
  },
  { 
    id: '2-5', 
    source: '2_registration', 
    target: '5_disinterest', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    type: 'step',
    sourceHandle: 'top-source',
    targetHandle: 'top-target',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: '89.37%'
  },
  { 
    id: '3-4', 
    source: '3_subscription', 
    target: '4_retention', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    animated: true,
    sourceHandle: 'top',
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: '60.94%'
  },
  { 
    id: '4-5', 
    source: '4_retention', 
    target: '5_disinterest', 
    type: 'bidirectional', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    sourceHandle: 'right-source',
    targetHandle: 'left-target',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
  },
  { 
    id: '3-5', 
    source: '3_subscription', 
    target: '5_disinterest',  
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    type: 'step',
    targetHandle: 'bottom-target',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: '39.05%'
  },
  { 
    id: '5-6', 
    source: '5_disinterest', 
    target: '6_churn', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    type: 'step',
    sourceHandle: 'right',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
  },
  { 
    id: '5-4', 
    source: '5_disinterest', 
    target: '4_retention', 
    type: 'bidirectional', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    sourceHandle: 'left-source',
    targetHandle: 'right-target',
    animated: true,
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
  },
  { 
    id: '6-3', 
    source: '6_churn', 
    target: '3_subscription', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3F88FE',
    },
    label: "winback = 66",
    animated: true,
    type: 'step',
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
  },

];

const edgeTypes = {
  bidirectional: BiDirectionalEdge,
};
 
const nodeTypes = {
  disinterest: DisinterestNode,
  retention: RetentionNode,
  subscription: SubscriptionNode,
  registration: RegistrationNode,
  firstvisit: FirstVisitNode,
  churn: ChurnNode,
  label: LabelNode
};
 
export function CustomerJourneyGraph({ setSelectedNode }) {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);


  const isFatMonitor = useMediaQuery({
    query: '(min-width: 2200px)',
  });

  const isBigMonitor = useMediaQuery({
    query: '(min-width: 1800px)',
  });


  const onInit = (rf) => {
    setReactFlowInstance(rf);
  };

  const handleNodeClick = (_, node) => {
    if (node){
      setSelectedNode(node.data.label)
      setNodes(nodes.map(n => {
        if(n.id === node.id){
          return {...n, selected: true}
        }
        return {...n, selected: false}
      }))
    } else {
      setSelectedNode(null)
      // setNodes(nodes)
    }
  }

  useEffect(() => {
    if (reactFlowInstance && nodes?.length) {
      reactFlowInstance.setViewport({ x: isFatMonitor ? 160 : isBigMonitor ? 40 : 5, y: 60, zoom: 0.99 })
    }
  }, [reactFlowInstance, nodes?.length, isFatMonitor, isBigMonitor]);
 
  return (
    <div style={{ maxWidth: 2400, height: 420 }}>
      <ReactFlow
        onInit={onInit}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodeClick={handleNodeClick}
        zoomOnScroll={false}
        preventScrolling={false}
        panOnDrag={false}
        onPaneClick={handleNodeClick}
      >
        <Background />
      </ReactFlow>
    </div>
  );
}
 
export default CustomerJourneyGraph;
