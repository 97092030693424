import React, { memo } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
} from '@xyflow/react';

 
const LabelNode = ({ data }) => {
  return (
    <div>
      <Typography style={{fontWeight: 'bold'}}>{data.label}</Typography>
    </div>
  );
};
 
export default memo(LabelNode);