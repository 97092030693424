import moment from 'moment';
import { makePercentage } from '../../utils';

export const COLORS_SERIES = ['#4BC0C0', '#f27173', '#36A2EB', '#ffce56', '#9966ff', '#ff9f40'];
export const PALE_COLORS_SERIES = [
  '#4bc0c0a6',
  '#f27173a6',
  '#36A2EBA6', 
  '#ffce56a6', 
  '#9966ffa6', 
  '#ff9f40a6'
];

export const SCMP_COLORS_SERIES = ['#3F88FE', '#FFCB05', '#001246', '#004BBB', '#003296', '#009FB9'];
export const SCMP_PALE_COLORS_SERIES = ['#3F88FEA6', '#FFCB05A6', '#001246A6', '#004BBBA6', '#003296A6', '#009FB9A6'];


export const CLASSIC_CYCLIC_13_COLORS = [
  '#1f83b4',
  '#12a2a8',
  '#2ca030',
  '#78a641',
  '#bcbd22',
  '#ffbf50',
  '#ffaa0e',
  '#ff7f0e',
  '#d63a3a',
  '#c7519c',
  '#ba43b4',
  '#8a60b0',
  '#6f63bb',
  '#1f83b4a6',
  '#12a2a8a6',
  '#2ca030a6',
  '#78a641a6',
  '#bcbd22a6',
  '#ffbf50a6',
  '#ffaa0ea6',
  '#ff7f0ea6',
  '#d63a3aa6',
  '#c7519ca6',
  '#ba43b4a6',
  '#8a60b0a6',
  '#6f63bba6',
];
export const OFFICE_BADGE_6_COLORS = [
  '#f8b323',
  '#656a59',
  '#46b2b5',
  '#8caa7e',
  '#d36f68',
  '#826276',
  '#f8b323a6',
  '#656a59a6',
  '#46b2b5a6',
  '#8caa7ea6',
  '#d36f68a6',
  '#826276a6',
];

export const DEFAULT_HEIGHT = 480;

export function tickFormatting(value) {
  return (value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    tooltip: {
      callbacks: {
        title: function(title) {
            if (moment(title[0].label).isValid() 
                && title[0] && (title[0].label.match(/[A-Za-z]/g) || []).length <= 1 
                && !(/^[0-9]+[.]{0,1}[0-9]*$/.test(title[0].label))
              ){
              return moment(title[0].label).format('YYYY-MM-DD')
            }
            if (/^[0-9]+[.]{0,1}[0-9]*$/.test(title[0].label)){
              return title[0].label;
            }
            return title[0].label;
        }
      }
    },
    datalabels: {
      display: false,
    }
  },
  scales: {
    x: {
        ticks: {
            callback: function(val, index) {
                var maxchar = 20;
                if (moment(this.getLabelForValue(val)).isValid() 
                    && !(/^[0-9]+[.]{0,1}[0-9]*$/.test(this.getLabelForValue(val)))
                    && (this.getLabelForValue(val).match(/[A-Za-z]/g) || []).length <= 1 
                  ){
                  return moment(this.getLabelForValue(val)).format('YYYY-MM-DD')
                }
                if (/^[0-9]+[.]{0,1}[0-9]*$/.test(this.getLabelForValue(val))){return this.getLabelForValue(val);}
                if (this.getLabelForValue(val).length <= maxchar) {return this.getLabelForValue(val);}
                else { try {return this.getLabelForValue(val).toString().substr(0, maxchar) + "...";} //truncate
                       catch(error) { console.error(error);}
                }
            },
        },
    },
    y: {
        beginAtZero: true,
        ticks: {
            callback: function (value, index, values) {
                return tickFormatting(value);
            }
        }
    }
  },
};