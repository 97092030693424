import React, { memo } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
} from '@xyflow/react';
 
 
const SubscriptionNode = ({ data }) => {
  return (
    <div>
      <Row>
        <Statistic title={data.label} value={5747} valueStyle={{fontSize: 16, color: '#001246'}}/>
      </Row>
      <Handle type="target" position={Position.Bottom} id="target" />
      <Handle type="target" position={Position.Left} id="left" />
      <Handle type="source" position={Position.Right} id="right" />
      <Handle type="source" position={Position.Top} id="top" />
      
    </div>
  );
};
 
export default memo(SubscriptionNode);