import { QueryBuilder } from '@cubejs-client/react';
import { Col, Row, Typography, Tooltip } from 'antd';
import { PlaySquareOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import DatasetSelector from '../../DatasetSelector/DatasetSelector'; 
import { Button, Card, Section } from '../../../atoms';
import SectionHeader from '../../../components/SectionHeader';
import SectionRow from '../../../components/SectionRow';
import { FatalError } from '../../../components/Error/FatalError';
import ChartContainer from '../../ChartContainer/ChartContainer';
import Export from '../../Export/Export';
import { useSecurityContext } from '../../../hooks';
import FilterGroup from '../../QueryBuilder/FilterGroup';
import MemberGroup from '../../QueryBuilder/MemberGroup';
import SelectChartType from '../../QueryBuilder/SelectChartType';
import TimeGroup from '../../QueryBuilder/TimeGroup';
import stateChangeHeuristics from "../../QueryBuilder/stateChangeHeuristics.js";
import { filterMembers, toSortAgainstFields } from '../../../config';
import { useQueryContext } from '../../QueryContext';
import { useCubeQuery } from '@cubejs-client/react';
import {playgroundActionUpdateMethods} from '../../../utils';


const Wrapper = styled.div`
  background-color: var(--layout-body-background);
  padding-bottom: 16px;
`;


export default function ExploreQueryBuilder({
  apiUrl,
  cubejsToken,
  queryId,
  dryRunError,
  handleRunButtonClick,
  renderProps,
  chartToDisplay,
  chartOption,
  setChartOption,
  metaExtended,
  initialVizState,
  onVizStateChanged,
  GASchema,
  mode,
  query
}) {

  const { decodeCheckExpiredToken, getUserDepartment, getUserName } = useSecurityContext();
  const [showCode, setShowCode] = useState(null);
  const { setChartToDisplay, setRenderProps, setGASchema, setSchemaChangedByUser, schemaChangedByUser } = useQueryContext();
  
  const { push } = useHistory();
  const [clearQuery, setClearQuery] = useState(false);
  const department = getUserDepartment();
  const userName = getUserName();
  const [limit, setLimit] = useState(10000);
  const [queryToRun, setQueryToRun] = useState({});
  const [dataTable, setDataTable] = useState('resultSet' in renderProps && renderProps.resultSet ? renderProps.resultSet.tablePivot(): []);
  const [columns, setColumns] = useState('resultSet' in renderProps && renderProps.resultSet ? renderProps.resultSet.tableColumns(): []);
  const [isSubDimsLimited, setIsSubDimsLimited] = useState(false);

  const renderP = useCubeQuery({...queryToRun, limit: limit}, {skip: Object.keys(queryToRun).length === 0});

  const handleClearQuery = (query, updateQuery) => {
    setClearQuery(true);
    updateQuery(query={measures: [], dimensions: [], timeDimensions: [], segments: [], filters: []})
    push('/insights/explore?query={}')
  }

  function handleRunButtonClick({
    query, chartType
  }) {
    // setQueryError(queryId, null);
    setRenderProps(renderProps)
    setQueryToRun(['area', 'bar', 'line'].includes(chartType) && 'dimensions' in query && query.dimensions.length > 0 && toSortAgainstFields.includes(query.dimensions[0]) ? {...query, order: {[query.dimensions[0]]: 'asc'}} : query)
    setChartToDisplay(chartType)
    setLimit(limit === 10000 ? 10001 : 10000)
    setChartOption(chartType === 'number' || chartType === 'table'? 'table' : 'chart')
    setIsSubDimsLimited(false);
  }

  useEffect(() => {
    renderP['id'] = queryId;
    setRenderProps(renderP)
  }, [renderP.resultSet, renderP.isLoading])
  

  return (
    <QueryBuilder
      initialVizState={initialVizState}
      query={query}
      wrapWithQueryRenderer={false}
      onVizStateChanged={onVizStateChanged}
      stateChangeHeuristics={stateChangeHeuristics}
      render={({
        query,
        error,
        metaError,
        richMetaError,
        metaErrorStack,
        meta,
        isQueryPresent,
        chartType,
        updateChartType,
        measures,
        updateMeasures,
        dimensions,
        updateDimensions,
        segments,
        updateSegments,
        filters,
        updateFilters,
        timeDimensions,
        updateTimeDimensions,
        missingMembers,
        updateQuery,
        isFetchingMeta,
        dryRunResponse,
        availableMembers,
        availableFilterMembers,
      }) => {
        availableFilterMembers = filterMembers(GASchema, mode, department, userName, availableFilterMembers, filters, metaExtended, query, 'filter')
        availableMembers.dimensions = filterMembers(GASchema, mode, department, userName, availableMembers.dimensions, dimensions, metaExtended, query, 'dimension')
        availableMembers.measures = filterMembers(GASchema, mode, department, userName, availableMembers.measures, measures, metaExtended, query, 'measure')
        availableMembers.timeDimensions = filterMembers(GASchema, mode, department, userName, availableMembers.timeDimensions, timeDimensions, metaExtended, query, 'time')
        availableMembers.segments = filterMembers(GASchema, mode, department, userName, availableMembers.segments, segments, metaExtended, query, 'segment')
        
        return (
          <Wrapper data-testid={`query-builder-${queryId}`}>
            <Row
              justify="space-around"
              align="top"
              gutter={24}
              style={{ marginBottom: 12 }}
            >
              <Col span={24}>
                <Card bordered={false} style={{ borderRadius: '0 8px 8px 8px'}}>
                  <Row align="top" gutter={0} style={{ marginBottom: -12 }}>
                    <Col span={22}>
                      <Row align="top" gutter={0} style={{ marginBottom: -12 }}>
                        <Section>
                          <SectionHeader>Metrics</SectionHeader>
                          <MemberGroup
                            disabled={isFetchingMeta}
                            members={measures}
                            availableMembers={availableMembers?.measures || []}
                            missingMembers={missingMembers}
                            addMemberName="Metric"
                            updateMethods={playgroundActionUpdateMethods(
                              updateMeasures,
                              'Measure'
                            )}
                          />
                        </Section>

                        <Section>
                          <SectionHeader>Dimensions</SectionHeader>
                          <MemberGroup
                            disabled={isFetchingMeta}
                            members={dimensions}
                            availableMembers={availableMembers?.dimensions || []}
                            missingMembers={missingMembers}
                            addMemberName="Dimension"
                            updateMethods={playgroundActionUpdateMethods(
                              updateDimensions,
                              'Dimension'
                            )}
                          />
                        </Section>

                        <Section>
                          <SectionHeader>Time</SectionHeader>
                          <TimeGroup
                            mode="query"
                            disabled={isFetchingMeta}
                            members={timeDimensions}
                            availableMembers={
                              availableMembers?.timeDimensions || []
                            }
                            missingMembers={missingMembers}
                            addMemberName="Time"
                            updateMethods={playgroundActionUpdateMethods(
                              updateTimeDimensions,
                              'Time'
                            )}
                          />
                        </Section>

                        <Section>
                          <SectionHeader>Pre-defined Filters</SectionHeader>
                          <MemberGroup
                            disabled={isFetchingMeta}
                            members={segments}
                            availableMembers={availableMembers?.segments || []}
                            missingMembers={missingMembers}
                            addMemberName="Pre-defined Filter"
                            updateMethods={playgroundActionUpdateMethods(
                              updateSegments,
                              'Segment'
                            )}
                          />
                        </Section>

                        <Section>
                          <SectionHeader>Filters</SectionHeader>
                          <FilterGroup
                            key='queryFilter'
                            disabled={isFetchingMeta}
                            members={filters}
                            availableMembers={availableFilterMembers}
                            missingMembers={missingMembers}
                            addMemberName="Filter"
                            updateMethods={playgroundActionUpdateMethods(
                              updateFilters,
                              'Filter'
                            )}
                          />
                        </Section>
                        
                      </Row>
                    </Col>
                    <Col
                        span={24}
                        style={{
                          marginTop: 16,
                          marginBottom: 16,
                        }}
                      >
                        <Row style={{ paddingTop: '24px' }} justify='center'>   
                          <Col span={6}>
                            {mode === 'reporting' && GASchema === 'GA4' && <DatasetSelector 
                              GASchema={GASchema}
                              setGASchema={setGASchema}
                              mode={mode}
                              setSchemaChangedByUser={setSchemaChangedByUser}
                              schemaChangedByUser={schemaChangedByUser}
                              metaExtended={metaExtended}
                              updateQuery={updateQuery}
                            />}              
                          </Col>
                          <Col span={12} align='center'>
                          <Button
                            icon={<PlaySquareOutlined />}
                            type="primary"
                            className="main-button"
                            loading={renderProps.isLoading}
                            disabled={!isQueryPresent || isFetchingMeta || dryRunError}
                            onClick={async () => {
                              await decodeCheckExpiredToken();
                              handleRunButtonClick({query, chartType});
                            }}
                          >
                            Run query
                          </Button>
                          
                          <Tooltip title="Clear query">
                            <Button 
                              style={{marginLeft: '12px', height: 34}} 
                              shape="circle" 
                              icon={<CloseOutlined />}  
                              disabled={renderProps.isLoading}
                              onClick={async () => handleClearQuery(query, updateQuery)}/>
                          </Tooltip>
                        </Col>
                        <Col span={6}><div></div></Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <SectionRow
                  style={{
                    marginTop: 16,
                    marginBottom: 0,
                  }}
                >
                  <Col span={6}>
                  {!showCode && <SelectChartType
                    chartOption={chartOption}
                    chartType={chartType}
                    updateChartOption={setChartOption}
                  />}
                  </Col>
                  <Col style={{marginLeft: 'auto'}}>
                    <Export
                      isQueryPresent={isQueryPresent}
                      disabled={isFetchingMeta}
                      csvData={dataTable}
                      csvHeader={columns}
                      chartOption={chartOption}
                      style={{marginLeft: 'auto'}}
                    />
                  </Col>
                    
                </SectionRow>
              </Col>
            </Row>

            <Row
              justify="space-around"
              align="top"
              style={{ margin: 0 }}
            >
              <Col span={24}>
                {!isQueryPresent && richMetaError ? (
                  <Card>
                    <FatalError error={richMetaError} stack={metaErrorStack} />
                  </Card>
                ) : null}

                  <ChartContainer
                    apiUrl={apiUrl}
                    cubejsToken={cubejsToken}
                    meta={meta}
                    dryRunQuery={query}
                    queryToRun={queryToRun}
                    isQueryPresent={isQueryPresent}
                    error={dryRunError}
                    chartType={chartToDisplay || 'line'}
                    chartOption={chartOption}
                    isFetchingMeta={isFetchingMeta}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                    columns={columns}
                    setColumns={setColumns}
                    clearQuery={clearQuery}
                    setClearQuery={setClearQuery}
                    showCode={showCode}
                    setShowCode={setShowCode}
                    renderProps={renderProps}
                    isSubDimsLimited={isSubDimsLimited}
                    setIsSubDimsLimited={setIsSubDimsLimited}
                    updateQuery={updateQuery}
                    handleClearQuery={handleClearQuery}
                    GASchema={GASchema}
                    mode={mode}
                    metaExtended={metaExtended}
                  />
                
              </Col>
            </Row>
          </Wrapper>
        );
      }}
    />
  );
}
