import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import App from './App';
import { ExplorePage, LoginPage, OauthRedirectPage, NoMatch, PipelineDashboardPage, PipelineSyncPage, PipelinesSyncsSummaryPage,
        PipelinesDashboardsSummaryPage, ArticleDashboardPage, AudienceBuilderPage, CohortRetentionChart, OtherDashboardsPage, CustomerJourneyPage } from './pages';
import { SecurityContextProvider } from './components/SecurityContext/SecurityContextProvider';
import PrivateRoute from './components/PrivateRoute';
import { createRoot } from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter basename='/app'>
      <SecurityContextProvider>
        <App>
          <Switch>
            <PrivateRoute exact path="/" component={({ location }) => {
                return <Redirect
                  to={{
                    ...location,
                    pathname: location.pathname.replace('/', '/insights/explore'),
                  }}
                />}}
            />
            <PrivateRoute exact path="/insights" component={({ location }) => {
                return <Redirect
                  to={{
                    ...location,
                    pathname: location.pathname.replace('/insights', '/insights/explore'),
                  }}
                />}}
            />
            <PrivateRoute key="explore" path="/insights/explore" >
              <ExplorePage />
            </PrivateRoute>
            <PrivateRoute key="journey" path="/insights/journey" >
              <CustomerJourneyPage />
            </PrivateRoute>
            <PrivateRoute key="audiences" path="/audience" exact >
              <AudienceBuilderPage />
            </PrivateRoute>
            <PrivateRoute key="article" path="/dashboards/article" >
              <ArticleDashboardPage />
            </PrivateRoute>
            <PrivateRoute key="retention" path="/dashboards/retention" >
              <CohortRetentionChart />
            </PrivateRoute>
            <PrivateRoute key="pipelinesdashboards" path="/pipelines/dashboards" >
              <PipelinesDashboardsSummaryPage />
            </PrivateRoute>
            <PrivateRoute key="pipelinessyncs" path="/pipelines/syncs" >
              <PipelinesSyncsSummaryPage />
            </PrivateRoute>
            <PrivateRoute key="pipelinedashboard" path="/pipelines/dashboard" >
              <PipelineDashboardPage />
            </PrivateRoute>
            <PrivateRoute key="pipelinesync" path="/pipelines/sync" >
              <PipelineSyncPage />
            </PrivateRoute>
            <PrivateRoute key="reports" path="/dashboards/reports" >
              <OtherDashboardsPage />
            </PrivateRoute>
            <Route key="login" path="/login">
              <LoginPage />
            </Route>
            <Route key="oauthcallback" path="/oauth/complete">
              <OauthRedirectPage />
            </Route>
            <Route path="*">
              <NoMatch />
          </Route>
          </Switch>
        </App>
      </SecurityContextProvider>
  </BrowserRouter>,
  // eslint-disable-next-line no-undef
);