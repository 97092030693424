import React, { Fragment, useEffect, useState } from 'react';
import { playgroundContext, buildApiUrl, filterMembers, backendApiUrl } from '../config';
import { useSecurityContext } from '../hooks';
import { DatePicker, Table, Tabs, Skeleton, Descriptions, Typography, Button, Collapse, notification, Avatar, Tooltip, Col, Row } from 'antd';
import { Container, Alert, Select } from '../atoms';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { QueryBuilder } from '@cubejs-client/react';
import { CustomerJourneyGraph } from '../components/CustomerJourney/CustomerJourneyGraph';
import MemberGroup from '../components/QueryBuilder/MemberGroup';
import SectionHeader from '../components/SectionHeader';
import { useCubeQuery } from '@cubejs-client/react';
import { useFilterValuesContext, FilterValuesContext } from '../components/FilterValuesContext';
import { QueryContext } from '../components/QueryContext';
import { useHistory } from 'react-router';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {playgroundActionUpdateMethods} from '../utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Link = styled.a`
&& {
    &:hover {
        opacity: 0.8;
        textDecoration: 'underline';
      }
}
`;

export function CustomerJourneyPage() {
  
  const [selectedNode, setSelectedNode] = useState(null);
  const [userAudiences, setUserAudiences] = useState([]);
  const [metaExtended, setMetaExtended] = useState([]);
  const [snapshotDate, setSnapshotDate] = useState(moment().hours() >= 14 ? moment().subtract(1, 'days') : moment().subtract(2, 'days'));
  const snapshotDate6monthsPrior = (moment().hours() >= 14 ? moment().subtract(1, 'days') : moment().subtract(2, 'days')).subtract(6, 'months');
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const { decodeCheckExpiredToken, getUserDepartment, getUserName, currentToken } = useSecurityContext();
  const department = getUserDepartment();
  const userName = getUserName();
  const { filterValues, fetchCubeJsPreAgg } = useFilterValuesContext();
  const location = useLocation();
  const { push } = useHistory();
  const GASchema = 'GA4';
  // filter selected
  const [firstVisitDateRange, setFirstVisitDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [registrationDateRange, setRegistrationDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [subscriptionStartDateRange, setSubscriptionStartDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [engagementDateRange, setEngagementDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [platformSelected, setPlatformSelected] = useState([]);
  const [audienceSelected, setAudienceSelected] = useState(null);
  const [subscriptionProductSelected, setSubscriptionProductSelected] = useState([]);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState([]);
  const [autoRenewStatusSelected, setAutoRenewStatusSelected] = useState(null);
  const [promoCodeSelected, setPromoCodeSelected] = useState([]);

  const getAudiencesFromDb = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audiences?sub=${jwtDecode(currentToken).sub}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUserAudiences(data.map(d => ({value: d.name, label: d.name})))
        });
  }

  const disabledDate = (current) => {
    if (!engagementDateRange) {
        return false;
      }
    const tooLate = engagementDateRange[0] && current.diff(engagementDateRange[0], 'days') > 365;
    const tooEarly = engagementDateRange[1] && engagementDateRange[1].diff(current, 'days') > 365;
    return (!!tooEarly || !!tooLate);
  }


  useEffect(() => {
    getAudiencesFromDb()
    // prepare dropdowns
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Events.country')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.subs_product')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.subscription_plan')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.auto_renew_status')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.promo_code')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Events.platform')
  }, [])


  return (
    
    <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        <QueryBuilder
            wrapWithQueryRenderer={false}
            onVizStateChanged={(vizState) => {
                // setQueryParam({query: vizState.query});
            }}
            render={({
                query,
                segments,
                updateSegments,
                timeDimensions,
                updateTimeDimensions,
                missingMembers,
                updateQuery,
                isFetchingMeta,
                availableMembers,
                dryRunError,
            }) => {
                availableMembers.timeDimensions = filterMembers(GASchema, 'retention', department, userName, availableMembers.timeDimensions, timeDimensions, metaExtended, query, 'time')
                availableMembers.segments = filterMembers(GASchema, 'retention', department, userName, availableMembers.segments, segments, metaExtended, query, 'segment')
                // console.log(query)
                return (
                    <Fragment>
                    <Container style={{ borderRadius: '8px', marginBottom: '16px'}}>
                    <Row justify="space-between" align="middle">
                        <Alert 
                            message={`This dashboard is work in progress and data is static... Only CAD can access for now`} 
                            type="info" 
                            showIcon
                        />
                        
                            <Typography style={{marginRight: '4px', fontStyle: 'italic'}}>{`Data last refreshed on: ${snapshotDate.format('YYYY-MM-DD')}`}</Typography>
                            {/* <DatePicker 
                                disabledDate={disabledDate}
                                defaultValue={snapshotDate}
                                allowClear={false}
                                disabled
                            /> */}
                        </Row>
                    </Container>
                    <Container style={{background: '#fff', padding: '0px 16px', borderRadius: '8px', marginBottom: '16px'}}>
                    <Tabs
                        defaultActiveKey="1"
                        // onChange={onChange}
                        items={[
                        {
                            label: `Using Filters`,
                            key: '1',
                            children: <Row>
                                        <Col span={24}>
                                            <Row align="middle" style={{marginBottom: '16px'}}>
                                                <Col span={6}>
                                                    <SectionHeader>First Visit Date</SectionHeader>
                                                    <RangePicker 
                                                        value={firstVisitDateRange}
                                                        onChange={setFirstVisitDateRange}
                                                        style={{ width: '92%' }}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Registration Date</SectionHeader>
                                                    <RangePicker
                                                        value={registrationDateRange}
                                                        onChange={setRegistrationDateRange}
                                                        style={{ width: '92%' }}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Subscription Start Date</SectionHeader>
                                                    <RangePicker
                                                        value={subscriptionStartDateRange}
                                                        onChange={setSubscriptionStartDateRange}
                                                        style={{ width: '92%' }}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Country (Most frequent)</SectionHeader>
                                                    <Select
                                                        mode="multiple"
                                                        maxTagCount="responsive"
                                                        tokenSeparators={[',', '\n']}
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="All"
                                                        onChange={setCountrySelected}
                                                        options={filterValues['Events.country']}
                                                        values={countrySelected}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row align="middle" style={{marginBottom: '16px'}}>
                                                <Col span={6}>
                                                    <SectionHeader>Subscription Product</SectionHeader>
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="All"
                                                        onChange={setSubscriptionProductSelected}
                                                        value={subscriptionProductSelected}
                                                        options={filterValues['Subscriptions.subs_product']}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Subscription Plan</SectionHeader>
                                                    <Select
                                                        mode="multiple"
                                                        maxTagCount="responsive"
                                                        tokenSeparators={[',', '\n']}
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="All"
                                                        onChange={setSubscriptionPlanSelected}
                                                        value={subscriptionPlanSelected}
                                                        options={filterValues['Subscriptions.subscription_plan']}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Promo Code</SectionHeader>
                                                    <Select
                                                        mode="multiple"
                                                        maxTagCount="responsive"
                                                        tokenSeparators={[',', '\n']}
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="All"
                                                        onChange={setPromoCodeSelected}
                                                        value={promoCodeSelected}
                                                        options={filterValues['Subscriptions.promo_code']}
                                                    />
                                                </Col>
                                                <Col span={6}>
                                                    <SectionHeader>Auto Renew Status</SectionHeader>
                                                    <Select
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="All"
                                                        onChange={setAutoRenewStatusSelected}
                                                        value={autoRenewStatusSelected}
                                                        options={filterValues['Subscriptions.auto_renew_status']}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>,
                        },
                        {
                            label: `Using Audience`,
                            key: '2',
                            children: <Row>
                                        <Col span={24}>
                                            <Row align="middle" style={{marginBottom: '16px'}}>
                                                <Col span={6}>
                                                    <SectionHeader>Audience</SectionHeader>
                                                    <Select
                                                        allowClear
                                                        style={{ width: '92%' }}
                                                        placeholder="Please select"
                                                        onChange={setAudienceSelected}
                                                        value={audienceSelected}
                                                        options={userAudiences}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>,
                        },
                        ]}
                    />
                    <Row>
                        <Typography style={{marginBottom: '16px', fontStyle: 'italic'}}>(affects Stage nodes)</Typography>
                    </Row>
                    </Container>
                    <Container style={{background: '#fff', padding: '16px', borderRadius: '8px', marginBottom: '16px'}}>
                        <Row>
                            <Col span={24}>
                                <Row align="middle">
                                    <Col span={6}>
                                        <SectionHeader>Engagement Date</SectionHeader>
                                        <RangePicker 
                                            disabledDate={disabledDate}
                                            value={engagementDateRange}
                                            onChange={setEngagementDateRange}
                                            style={{ width: '92%' }}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <SectionHeader>Platform</SectionHeader>
                                        <Select
                                            mode="multiple"
                                            maxTagCount="responsive"
                                            tokenSeparators={[',', '\n']}
                                            allowClear
                                            style={{ width: '92%' }}
                                            placeholder="All"
                                            onChange={setPlatformSelected}
                                            value={platformSelected}
                                            options={filterValues['Events.platform']}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Typography style={{marginTop: '16px', fontStyle: 'italic'}}>(affects Engagement nodes)</Typography>
                        </Row>
                    </Container>
                    <Container style={{background: '#fff', padding: '16px', borderRadius: '8px', marginBottom: '16px'}}>
                        <Row>
                            <Title level={5}>Customer paths</Title>
                            <Typography style={{marginLeft: '16px', marginTop: '2px', fontStyle: 'italic'}}> (click on a node and scroll down to discover insights)</Typography>
                        </Row>
                        <CustomerJourneyGraph setSelectedNode={setSelectedNode} />
                    </Container>
                    {selectedNode === 'First Visit' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Alert 
                            message="Users are anonymous at this stage, we only track them thanks to their cookie set on the device. Therefore it is possible that real persons appear multiple times under different cookie IDs." 
                        type="info" showIcon/>
                        <Col span={24} style={{marginTop: '8px'}}>
                            1. How are readers reaching SCMP.com? (traffic source) <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. How many articles in avg are they consuming (apv/100% completions total and by topic)? And how long are they staying on average? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            3. How many of them do come back? (hint for developer: using fact_daily_known_users 90 days prior to registration). How many of them register in that session? <b>[HARD]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Registration' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Col span={24}>
                            1. How long between first visit and registration? (median, min, max)<b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. How many articles in avg have they consume before registering? How many of them did complete the onboarding? What NL do they subscribe to during onboarding? <b>[HARD]</b>
                        </Col>
                        <Col span={24}>
                            3. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Subscription' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Col span={24}>
                            1. How long between first visit and subscription, and how long between registration and subscription? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Last touch session: which article was their last? what was the entrypoint? what is their loyalty at subscription? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            3. Some stats: the split per product/plan, how many of them are winback? <b>[EASY]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Retention' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Col span={24}>
                            1. What is their loyalty? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Look at SCMP triggers (NL, push), how much of it is being consumed in avg. Compare with direct visits.
                            How many triggers on avg daily (NL & app)  <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            3. Any actions detected helping forming habit? <b>[HARD]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Disinterest' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Col span={24}>
                            1. When did their loyalty drop? How long after registering or subscribing? <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            2. What is their loyalty? What is their weekly retention? <b>[HARD]</b>
                        </Col>
                        <Col span={24}>
                            3. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Churn' ? <Container style={{background: '#fff', padding: '16px', borderRadius: '8px'}}>
                        <Col span={24}>
                            <Title level={5}>{selectedNode}</Title>
                        </Col>
                        <Col span={24}>
                            1. What is their loyalty? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            3. Comparison of engagement levels before and after churning <b>[HARD]</b>
                        </Col>
                    </Container>
        : <></>}
        </Fragment>)
            }}
        />
    </QueryContext>
  );
}