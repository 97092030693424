import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';

export const Tabs = styled(AntdTabs)`
&& {
  .ant-tabs-nav {
    background:var(--layout-body-background);
    margin: 0;
  }
  .ant-tabs-extra-content {
    margin-left: 32px;
  }
  .ant-tabs-tab.ant-tabs-tab-with-remove {
    padding: 4px 2px 4px 2px;
    border-radius: 8px 8px 0 0;
    border-left: #fff;
  }
  button.ant-tabs-tab-remove {
    margin: 0;
  }
}
`;


export const noCloseTabs = styled(AntdTabs)`
&& {
  .ant-tabs-nav {
    background:var(--layout-body-background);
    margin: 0;
  }
  .ant-tabs-extra-content {
    margin-left: 32px;
  }
  .ant-tabs-tab.ant-tabs-tab-with-remove {
    padding: 4px 2px 4px 2px;
    border-radius: 8px 8px 0 0;
    border-left: #fff;
  }
  button.ant-tabs-tab-remove {
    margin: 0;
  }
}
`;